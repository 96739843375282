import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/SwKVJ1Yk0pAoLOkNqBKD/locations/NKEXd7S4yDT4NNQr7plR`}
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="100%"
                  maxWidth="260px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/SwKVJ1Yk0pAoLOkNqBKD/locations/NKEXd7S4yDT4NNQr7plR`}
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="100%"
                  maxWidth="300px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
